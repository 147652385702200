import React from 'react';

import Layout from '../components/Layout';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

export default function WoSieUnsFinden() {
	return (
		<Layout title="Wo Sie uns finden">
			<h2>Wo Sie uns finden</h2>

			<Container fluid="lg">
				<Row>
					<Col lg="4">
						<p>
							<b>Kreisverband BGL / Verwaltung:</b>
						</p>
						<p>
							BRK Bereitschaft Bad Reichenhall
							<br />
							Riedelstraße 18
							<br />
							83435 Bad Reichenhall
							<br />
							Tel: +49 (0) 8651 95 90-0
							<br />
							Fax: +49 (0) 8651 95 90-50
							<br />
							Email: <a href="mailto:info@brk-bad-reichenhall.de">info@brk-bad-reichenhall.de</a>
						</p>
					</Col>
					<Col lg="4">
						<p>
							<b>Einsatzdienst:</b>
						</p>
						<p>
							BRK Bereitschaft Bad Reichenhall
							<br />
							Schnell-Einsatz-Gruppe (SEG)
							<br />
							Reichenhallerstraße 109a
							<br />
							83435 Bad Reichenhall
							<br />
							Tel: +49 (0) 8651 – 96 57 47 0
							<br />
							<span className="brk-important">(Nur im Einsatzfall besetzt!)</span>
						</p>
					</Col>
					<Col lg="4">
						<p>
							<b>Senden Sie uns direkt eine Nachricht:</b>
						</p>
						<p>
							Bitte richten Sie Ihre Anfragen per Email an
							<br />
							<a href="mailto:info@brk-bad-reichenhall.de">info@brk-bad-reichenhall.de</a>
						</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
}
